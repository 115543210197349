
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ITeacher } from "@/store/modules/TeacherModule";

export default defineComponent({
  name: "add-teacher-modal",
  components: {},
  setup(props, context) {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const CreateTeacherModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const formData = ref({
      name: "",
      weekday: [new Date(2016, 9, 10, 8, 0), new Date(2016, 9, 10, 17, 0)],
      weekend: [new Date(2016, 9, 10, 8, 0), new Date(2016, 9, 10, 17, 0)],
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Teacher name is required",
          trigger: "change",
        },
      ],
    });

    const dateToTime = (date: any): string => {
      if (date) {
        let hrs: any = date.getHours();
        let mins: any = date.getMinutes();
        if (hrs < 10) hrs = "0" + hrs;
        if (mins < 10) mins = "0" + mins;

        return `${hrs}:${mins}:00`;
      } else return "";
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let teacher: ITeacher = {
            name: formData.value.name,
            startTimeRangeWeekday: dateToTime(formData.value.weekday[0]),
            endTimeRangeWeekday: dateToTime(formData.value.weekday[1]),
            startTimeRangeWeekend: dateToTime(formData.value.weekend[0]),
            endTimeRangeWeekend: dateToTime(formData.value.weekend[1]),
          };
          store
            .dispatch(Actions.ADD_TEACHER, teacher)
            .then(() => {
              Swal.fire({
                text: "Teacher has been created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Continue",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Close modal after pressing confirm button
                const closeButton: any = document.querySelector(
                  "#kt_modal_create_teacher_close"
                );
                if (closeButton) closeButton.click();

                var body: any = document.querySelector("#kt_body");
                body.style.overflow = "auto";
              });
              loading.value = false;
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              loading.value = false;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      CreateTeacherModalRef,
    };
  },
});
