
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeMount,
  computed,
  ComputedRef,
} from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import CreateTeacherModal from "@/components/modals/forms/CreateTeacherModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { ITeacher } from "@/store/modules/TeacherModule";

export default defineComponent({
  name: "groups-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    CreateTeacherModal,
  },
  setup() {
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Teacher id",
        key: "teacherId",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Weekday Start",
        key: "startTimeRangeWeekday",
        sortable: true,
      },
      {
        name: "Weekday End",
        key: "endTimeRangeWeekday",
        sortable: true,
      },
      {
        name: "Weekend Start",
        key: "startTimeRangeWeekend",
        sortable: true,
      },
      {
        name: "Weekend End",
        key: "endTimeRangeWeekend",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const teachers: ComputedRef<Array<ITeacher>> = computed(
      () => store.state.TeacherModule.teachers
    );

    onBeforeMount(() => {
      if (store.state.TeacherModule.teachers.length === 0)
        store.dispatch(Actions.GET_TEACHERS);
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      // initGroups.value.splice(0, groups.value.length, ...groups.value);
      setCurrentPageBreadcrumbs("Teachers Listing", ["Apps", "Teachers"]);
    });

    return {
      teachers,
      tableHeader,
    };
  },
});
